var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "help-main" }, [
    _c("div", { staticClass: "help-title" }, [
      _c(
        "div",
        { staticClass: "menu" },
        [
          _c(
            "en-tabs",
            {
              attrs: { list: _vm.tabList, type: "white" },
              on: { change: _vm.changeMenu },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _vm.activeTab === "1" || _vm.activeTab === "2"
                ? _c(
                    "div",
                    {
                      staticClass: "title-right",
                      attrs: { slot: "right" },
                      slot: "right",
                    },
                    [
                      _c("en-search", {
                        attrs: { showBorder: _vm.hasBorder },
                        on: { search: _vm.search },
                        model: {
                          value: _vm.input,
                          callback: function ($$v) {
                            _vm.input = $$v
                          },
                          expression: "input",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.activeTab === "1" && !_vm.isOnSearch ? _c("firstPager") : _vm._e(),
        _vm.isOnSearch
          ? _c("searchPager", {
              attrs: { searchTxt: _vm.input, searchType: _vm.activeTab },
            })
          : _vm._e(),
        _vm.activeTab === "2" && !_vm.isOnSearch ? _c("helpProblem") : _vm._e(),
        _vm.activeTab === "3" ? _c("helpLogList") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }