<!--
 * @Author: 张小兵
 * @Date: 2020-07-24 10:57:40
 * @LastEditors: zhulin
 * @LastEditTime: 2021-05-20 16:32:35
 * @Description: 帮助中心日志页
 * @FilePath: \user\src\views\help\helpLogList.vue
-->
<template>
  <div class="help-journal">
    <div class="loading" v-en-loading="isLoading">
      <div class="item-main" v-for="(item, index) in logList" :key="index">
        <div class="year-line">
          <div>
            <div class="outer-ring"></div>
            <div class="inner-ring"></div>
          </div>
          <div class="year">{{ item.data }}</div>
        </div>
        <div v-if="item.itemList.length > 0">
          <div class="item-list" v-for="(item1, index1) in item.itemList" :key="index + '-' + index1">
            <div class="item-ring"></div>
            <div class="item-right-block">
              <div class="item-line">
                <div class="item-subject">{{ item1.subject }}</div>
                <div class="item-date">{{ item1.createDate }}</div>
                <div class="item-data">{{ item1.data }}</div>
              </div>
              <div class="item-content">{{ item1.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="nodata" v-if="(logList === null || logList.length === 0) && !isLoading">
        <en-result type="NoData"></en-result>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { request } from "en-js";
import { helpService } from "@/api/help/index";

export default {
  data() {
    return {
      logList: [], // 日志列表数据
      isLoading: true // 是否正在加载数据
    };
  },
  mounted() {
    this.querySysNoticeList();
  },
  methods: {
    // 查询日志列表信息
    @request(true, "isLoading", {
      subTitle: "数据加载中"
    })
    async querySysNoticeList() {
      const rspData = await helpService.querySysNoticeList({
        pageNo: 1,
        pageSize: 999,
        noticeType: "000",
        isAdmin: "0"
      });
      this.pocessData(rspData);
    },
    // rspData数据处理
    pocessData(rspData) {
      const record = rspData.records;
      // 循环列表数据
      for (let i = 0; i < record.length; i++) {
        const item = record[i];
        const date = new Date(item.createDate);
        const dateStr = dayjs(date).format("YYYY");
        let flag = true; // 作标记是否要插入一条新数据
        // 循环本地定义的列表结构数组
        for (let j = 0; j < this.logList.length; j++) {
          const logItem = this.logList[j];
          // 将相同年份记录插入同一条数据结构中
          if (dateStr === logItem.data) {
            logItem.itemList.push(item);
            flag = false;
            break;
          }
        }
        // 本地记录中未找到相同年份的记录，插入一条新记录
        if (flag) {
          const obj = {
            data: dateStr,
            itemList: []
          };
          obj.itemList.push(item);
          this.logList.push(obj);
        }
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.help-journal{
  height:100%;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  border-top:1px solid #f3f3f3;
  .loading{
    padding: 0 20px;
  }
  .year-line {
    height: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    position: relative;
    .outer-ring {
      height: 16px;
      width: 16px;
      position: absolute;
      left: 20px;
      border-radius: 50%;
      border: 1px solid #329bd4;
    }
    .inner-ring {
      height: 10px;
      width: 10px;
      left: 23px;
      top: 3px;
      position: absolute;
      border-radius: 50%;
      background: #329bd4;
    }
    .year {
      font-size: 14px;
      color: #333;
      left: 36px;
      margin-left: 10px;
      position: absolute;
      font-family: Microsoft YaHei;
      font-weight: bold;
    }
  }
  .item-list {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    margin-left: 23px;
    align-items: center;
    .item-ring {
      height: 10px;
      width: 11px;
      border-radius: 5px;
      background: #4993dc;
    }
    .item-right-block {
      width: 100%;
      padding: 20px;
      margin:2px 20px 0 10px;
      position: relative;
      background-color: #f5f8fd;
      border-radius: 0 4px 4px 0;
      border:1px solid #eaebef;
      border-left: 2px solid #5a8fc3;
      .item-line {
        width: 100%;
        display: flex;
        .item-subject {
          font-size: 12px;
          color: #333;
          font-family: Microsoft YaHei;
          font-weight: bold;
        }
        .item-data {
          flex: auto;
          text-align: right;
          font-size: 12px;
          color: #636c78;
          font-family: Microsoft YaHei;
        }
        .item-date{
          position:absolute;
          font-size:12px;
          color:#aeaeae;
          right:20px!important;
          top:20px;
        }
      }
      .item-content {
        width: 100%;
        text-align: left;
        margin-top: 10px;
        font-size: 12px;
        color: #636c78;
        word-break: break-all;
        font-family: Microsoft YaHei;
      }
    }
  }
}
</style>
