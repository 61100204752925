<!--
 * @Author: 张小兵
 * @Date: 2020-07-24 10:57:40
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-13 10:20:32
 * @Description: 帮助中心首页结构页
 * @FilePath: \user\src\views\help\index.vue
-->
<template>
  <div class="help-main">
    <div class="help-title">
      <div class="menu">
        <en-tabs :list="tabList" type="white" v-model="activeTab" @change="changeMenu">
          <div class="title-right" v-if="activeTab === '1' || activeTab === '2'" slot="right">
            <en-search v-model="input" @search="search" :showBorder="hasBorder"> </en-search>
          </div>
        </en-tabs>
      </div>
    </div>
    <div class="content">
      <firstPager v-if="activeTab === '1' && !isOnSearch"></firstPager>
      <searchPager v-if="isOnSearch" :searchTxt="input" :searchType="activeTab"></searchPager>
      <helpProblem v-if="activeTab === '2' && !isOnSearch"></helpProblem>
      <helpLogList v-if="activeTab === '3'"></helpLogList>
    </div>
  </div>
</template>

<script>
import firstPager from "./mainContent";
import searchPager from "./helpSearchList";
import helpProblem from "./helpProblem";
import helpLogList from "./helpLogList";

export default {
  components: {
    firstPager, // 帮助中心主页
    searchPager, // 搜索页
    helpProblem, // 帮助问题页
    helpLogList // 更新日志页
  },
  data() {
    return {
      tabList: [
        {
          id: "1",
          name: "帮助文档"
        },
        {
          id: "2",
          name: "常见问题"
        },
        {
          id: "3",
          name: "更新日志"
        }
      ],
      activeTab: "1", // 当前标题栏选中标记值
      // isSearch: true, // 是否显示标题栏右侧的搜索框
      input: "", // 搜索框输入值,
      oldTxt: "",
      isOnSearch: false, // 是否显示帮助搜索页
      hasBorder: false
    };
  },
  methods: {
    search() {
      if (this.oldTxt === this.input) {
        this.input = "";
        this.hasBorder = false;
        this.isOnSearch = false;
      } else {
        this.isOnSearch = !!this.input;
      }
      this.oldTxt = this.input;
    },
    changeMenu() {
      this.input = " ";
      this.hasBorder = false;
      this.isOnSearch = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.help-title {
  width: 100%;
  height: 50px;
  background-color: white;
  align-items: center;
  display: flex;
  border-radius: 5px;
  .menu {
    width: 100%;
  }
  .title-right {
    height: 50px;
    display: flex;
  }
  /deep/ .en-search {
    height: auto;
    .en-icon {
      cursor: pointer;
    }
  }
}
.content {
  height: calc(100% - 50px);
}
</style>
