var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-card" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "en-loading",
            rawName: "v-en-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "card-list",
      },
      [
        _vm._l(_vm.cardList, function (item, index) {
          return _c("div", { key: index, staticClass: "card" }, [
            _c("img", {
              staticClass: "row-img",
              attrs: { src: require("@/assets/notimport.png") },
            }),
            _c("div", { staticClass: "row-cent" }, [
              _c("div", { staticClass: "main-title" }, [
                _c("div", { staticClass: "txt-key" }, [
                  _vm._v(_vm._s(item.type === 1 ? "帮助文档:" : "常见问题:")),
                ]),
                _c("div", { staticClass: "txt-value" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]),
              _c("div", {
                staticClass: "sub-title",
                domProps: { innerHTML: _vm._s(item.relContentStr) },
              }),
            ]),
          ])
        }),
        (_vm.cardList === null || _vm.cardList.length === 0) && !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "nodata" },
              [
                _c("en-result", {
                  attrs: { type: "NoData", subTitle: _vm.text() },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
    _c("div", { staticClass: "card-bottom" }, [
      _c("div", { staticClass: "result-txts" }, [
        _vm._v("搜索结果，找到" + _vm._s(_vm.cardList.length) + "个结果"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }