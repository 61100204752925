var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "help-journal" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "en-loading",
            rawName: "v-en-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "loading",
      },
      [
        _vm._l(_vm.logList, function (item, index) {
          return _c("div", { key: index, staticClass: "item-main" }, [
            _c("div", { staticClass: "year-line" }, [
              _vm._m(0, true),
              _c("div", { staticClass: "year" }, [_vm._v(_vm._s(item.data))]),
            ]),
            item.itemList.length > 0
              ? _c(
                  "div",
                  _vm._l(item.itemList, function (item1, index1) {
                    return _c(
                      "div",
                      { key: index + "-" + index1, staticClass: "item-list" },
                      [
                        _c("div", { staticClass: "item-ring" }),
                        _c("div", { staticClass: "item-right-block" }, [
                          _c("div", { staticClass: "item-line" }, [
                            _c("div", { staticClass: "item-subject" }, [
                              _vm._v(_vm._s(item1.subject)),
                            ]),
                            _c("div", { staticClass: "item-date" }, [
                              _vm._v(_vm._s(item1.createDate)),
                            ]),
                            _c("div", { staticClass: "item-data" }, [
                              _vm._v(_vm._s(item1.data)),
                            ]),
                          ]),
                          _c("div", { staticClass: "item-content" }, [
                            _vm._v(_vm._s(item1.content)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        }),
        (_vm.logList === null || _vm.logList.length === 0) && !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "nodata" },
              [_c("en-result", { attrs: { type: "NoData" } })],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "outer-ring" }),
      _c("div", { staticClass: "inner-ring" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }